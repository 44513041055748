<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item :to="{ path: '/dashboard/category' }"
          >分类列表</el-breadcrumb-item
        >
      </el-breadcrumb>
      <div class="operation-nav">
        <router-link to="/dashboard/category/add">
          <el-button type="primary" icon="plus">添加分类</el-button>
        </router-link>
      </div>
    </div>
    <div class="content-main">
      <div class="filter-box">
        <!-- <el-form :inline="true" :model="filterForm" class="demo-form-inline">
          <el-form-item label="店铺名称">
            <el-input v-model="filterForm.name" placeholder="店铺名称"></el-input>
          </el-form-item>
 -->
          <el-form-item>
            <el-button type="primary" @click="onSubmitFilter">刷新</el-button>
            <!-- <el-button @click="resetFilter">重置</el-button> -->
          </el-form-item>
        </el-form>
      </div>
      <div class="form-table-box">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column prop="icon_url" label="分类图标" width="80">
                  <template scope="scope">
                    <img :src="scope.row.icon_url" alt="" style="width: 40px; height: 40px" />
                  </template>
           </el-table-column>
          <el-table-column prop="name" label="分类名称">
            <template scope="scope">
              <div>{{ scope.row.name }}</div>
            </template>
          </el-table-column>

          <el-table-column label="展示状态" width="80">
            <template scope="scope">
              <el-switch
                v-model="scope.row.delete"
                active-text=""
                inactive-text=""
                :active-value="0"
                :inactive-value="1"
                @change="changeShowStatus($event, scope.row.id)"
              >
              </el-switch>
            </template>
          </el-table-column>

          <el-table-column prop="delete" label="状态">
            <template scope="scope">
              <div>{{ scope.row.delete == 1 ? '不展示' : '正常' }}</div>
            </template>
          </el-table-column>

          <el-table-column prop="sort" label="排序" width="100" sortable>
            <template scope="scope">
              <el-input
                v-model="scope.row.sort"
                placeholder="排序"
                @blur="submitSort(scope.$index, scope.row)"
              ></el-input>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="300">
            <template scope="scope">
              <el-button size="small" type="primary" @click="handleRowEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
              <!-- <el-button
                size="small"
                type="danger"
                @click="handleRowDelete(scope.$index, scope.row)"
                >删除
              </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div class="page-box">
          <el-pagination
            background
            @current-change="handlePageChange"
            :current-page.sync="page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      total: 0,
      filterForm: {
        name: "",
      },
      tableData: [],
    };
  },
  methods: {
    changeShowStatus($event, para) {
      this.axios
        .get("category/brandGoodsCateShow", {
          params: {
            status: $event,
            id: para,
          },
        })
        .then((response) => {});
    },
    handlePageChange(val) {
      this.page = val;
      this.getList();
    },

    submitSort(index, row) {
      this.axios
        .post("category/brandGoodsCateUpdateSort", {
          id: row.id,
          sort: row.sort,
        })
        .then((response) => {});
    },
    handleRowEdit(index, row) {
      this.$router.push({ name: 'category_add', query: { id: row.id } });
    },
    handleRowDelete(index, row) {
      this.$confirm("确定要删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.axios
          .post("category/brandCateDestory", { id: row.id })
          .then((response) => {
            console.log(response.data);
            if (response.data.errno === 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });

              this.getList();
            } else {
              this.$message({
                type: "error",
                message: "删除失败!",
              });
            }
          });
      });
    },
    onSubmitFilter() {
      this.page = 1;
      this.getList();
    },
    resetFilter() {
      this.filterForm = {
        name: "",
      };
      this.getList();
    },
    getList() {
      this.axios
        .get("category/index", {
          params: {
            page: this.page,
          },
        })
        .then((response) => {
          this.tableData = response.data.data.data;
          this.page = response.data.data.currentPage;
          this.total = response.data.data.count;
        });
    },
  },
  components: {},
  mounted() {
    this.getList();
  },
};
</script>

<style scoped>
.sub-category .el-table__expanded-cell {
  padding: 0;
}

.bg-gray {
  /* background:gray; */
  color: red;
  font-weight: bold;
}

.bg-left {
  margin-left: 30px;
}
</style>
